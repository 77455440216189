import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const classBullets = [
  'Access to the instruction videos',
  'Handouts of the class',
  '2 Question hours',
  'The client brief',
]
const projectBullets = [
  '2 Question hours',
  'The client brief',
]

const cards = [
  {
    title: 'Classes',
    price: '€45',
    timeframe: '/month',
    description: 'Pick and choose from already released classes or pre-order something for the future!',
    cta: 'Buy',
    bullets: classBullets,
    type: 'class'
  },
  {
    title: 'Projects',
    price: '€10',
    timeframe: '/month',
    description: 'Pick and choose from already released projects or pre-order something for the future!',
    cta: 'Buy',
    bullets: projectBullets,
    type: 'project'
  }
]

const PriceCard = (it) =>
  <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
      <div>
        <h3
          className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-blue-100 text-blue-700"
          id="tier-standard">
          {it.title}
        </h3>
      </div>
      <div
        className="mt-4 flex items-baseline text-6xl leading-none font-extrabold text-gray-800">
        {it.price}
        <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                                            {it.timeframe}
                                            </span>
      </div>
      <p className="mt-5 text-lg leading-7 text-gray-500">
        {it.description}
      </p>
    </div>
    <div
      className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
      <ul className="space-y-4">
        {it.bullets.map(it =>
          <li key={it} className="flex items-start">
            <div className="flex-shrink-0">
              <svg className="h-6 w-6 text-green-500"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7" />
              </svg>
            </div>
            <p className="ml-3 text-base leading-6 text-gray-700">{it}</p>
          </li>
        )}
      </ul>
      <div className="rounded-md shadow">
        <Link to="/purchase"
              state={{ type: it.type }}
              className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring transition duration-150 ease-in-out"
              aria-describedby="tier-standard">
          {it.cta}
        </Link>
      </div>
    </div>
  </div>

const Payments = () => (
  <Layout>
    <div className="bg-gray-900">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <p
              className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-4xl lg:leading-none">
              Get a class with video instructions and handouts. Or get a project without instructions but the option to
              ask questions if you get stuck.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-gray-900">&nbsp;</div>
          <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {cards.map(it => <PriceCard key={it.title} {...it} />)}
            </div>
          </div>
        </div>
        <div className="mt-4 relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
          <div className="max-w-md mx-auto lg:max-w-5xl">
            <div className="rounded-t-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
              <div className="flex-1">
                <div>
                  <h3
                    className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-white text-gray-800">
                    Just need a spark of inspiration?
                  </h3>
                </div>

                <div className="mt-4 text-lg leading-7 text-gray-600">
                  Client brief ONLY (no access to the question hours)&nbsp;
                  <strong className="font-semibold text-gray-900">
                    €5/month
                  </strong>
                  .
                </div>

              </div>
              <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                <Link to="/purchase" state={{ type: 'brief' }}

                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none focus:ring transition duration-150 ease-in-out">
                  Buy Client Briefs
                </Link>
              </div>

            </div>
            <div className=" text-gray-700 rounded-b-lg bg-gray-100 px-6 py-4 sm:px-10 sm:py-4 lg:flex lg:items-center">
              Purchased the client brief or project but need some extra assistance?<br /> You can pay the
              difference and upgrade.<br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Payments
